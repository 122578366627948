<template>
  <AppPageCard>
    <template #card-title>
      {{ $t("PERSONAL.ACCOUNTDETAILS") }}
    </template>
    <template #card-toolbar>
      <!-- begin: b-nav tabs  -->
      <b-nav pills class="nav-light-primary font-weight-bolder">
        <b-nav-item to="email" exact exact-active-class="active"
          >{{ $t("EMPLOYEESGENERAL.MESSAGE.EMAIL") }}
        </b-nav-item>
        <b-nav-item to="password" exact exact-active-class="active"
          >{{ $t("LOGIN.PASSWORD") }}
        </b-nav-item>
      </b-nav>
      <!-- end: b-nav tabs  -->
    </template>
    <router-view></router-view>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "PersonalAccountPage",
  components: { AppPageCard },
};
</script>

<style scoped></style>
